import env from 'config/settings';

export type Route =
  | 'layoutDrivers'
  | 'layoutAdCompanies'
  | 'termsAndConditions'
  | 'consentToDataProcessing'
  | 'privacyPolicy';

export const Routes: Record<Route, string> = {
  layoutDrivers: '/drivers',
  layoutAdCompanies: '/ads',
  termsAndConditions: '/terms-and-conditions',
  consentToDataProcessing: '/consent-to-data-processing',
  privacyPolicy: '/terms-and-conditions#privacy-policy',
};

export type ExternalRoute = 'adminAuth';

export const ExternalRoutes: Record<ExternalRoute, string> = {
  adminAuth: `${env.ADMIN_HOST}/auth`,
};
