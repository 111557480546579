import React, { FC, useEffect, useState } from 'react';
import LandingDesktopLayout from './elements/sidebar/desktop';
import LandingMobileLayout from './elements/sidebar/mobile';
import { Routes } from '../../constants/Routes';

type IProps = {};

export const maxIPadProSize = 1440;
export const maxIPadMiniSize = 768;
export const maxIphoneSize = 576;

type TMenuItem = {
  id: number;
  title: string;
  route: string;
};

type TCity = {
  id: number;
  displayName: string;
};

export const CONST_LIST_CITY: TCity[] = [
  { id: 1, displayName: 'Абакан' },
  { id: 1, displayName: 'Альметьевск' },
  { id: 1, displayName: 'Ангарск' },
  { id: 1, displayName: 'Армавир' },
  { id: 1, displayName: 'Астрахань' },
  { id: 1, displayName: 'Балаково' },
  { id: 1, displayName: 'Балашиха' },
  { id: 1, displayName: 'Барнаул' },
  { id: 1, displayName: 'Батайск' },
  { id: 1, displayName: 'Белгород' },
  { id: 1, displayName: 'Березники' },
  { id: 1, displayName: 'Бийск' },
  { id: 1, displayName: 'Благовещенск' },
  { id: 1, displayName: 'Братск' },
  { id: 1, displayName: 'Брянск' },
  { id: 1, displayName: 'Великий Новгород' },
  { id: 1, displayName: 'Владивосток' },
  { id: 1, displayName: 'Владикавказ' },
  { id: 1, displayName: 'Владимир' },
  { id: 1, displayName: 'Волгоград' },
  { id: 1, displayName: 'Волгодонск' },
  { id: 1, displayName: 'Волжский' },
  { id: 1, displayName: 'Вологда' },
  { id: 1, displayName: 'Воронеж' },
  { id: 1, displayName: 'Грозный' },
  { id: 1, displayName: 'Дербент' },
  { id: 1, displayName: 'Дзержинск' },
  { id: 1, displayName: 'Долгопрудный' },
  { id: 1, displayName: 'Домодедово' },
  { id: 1, displayName: 'Екатеринбург' },
  { id: 1, displayName: 'Иваново' },
  { id: 1, displayName: 'Ижевск' },
  { id: 1, displayName: 'Иркутск' },
  { id: 1, displayName: 'Йошкар-Ола' },
  { id: 1, displayName: 'Казань' },
  { id: 1, displayName: 'Калининград' },
  { id: 1, displayName: 'Калуга' },
  { id: 1, displayName: 'Каменск - Уральский' },
  { id: 1, displayName: 'Кемерово' },
  { id: 1, displayName: 'Киров' },
  { id: 1, displayName: 'Ковров' },
  { id: 1, displayName: 'Коломна' },
  { id: 1, displayName: 'Комсомольск-на-Амуре' },
  { id: 1, displayName: 'Королёв' },
  { id: 1, displayName: 'Кострома' },
  { id: 1, displayName: 'Красногорск' },
  { id: 1, displayName: 'Краснодар' },
  { id: 1, displayName: 'Красноярск' },
  { id: 1, displayName: 'Курган' },
  { id: 1, displayName: 'Курск' },
  { id: 1, displayName: 'Липецк' },
  { id: 1, displayName: 'Люберцы' },
  { id: 1, displayName: 'Магнитогорск' },
  { id: 1, displayName: 'Майкоп' },
  { id: 1, displayName: 'Махачкала' },
  { id: 1, displayName: 'Миасс' },
  { id: 1, displayName: 'Москва' },
  { id: 1, displayName: 'Мурманск' },
  { id: 1, displayName: 'Мытищи' },
  { id: 1, displayName: 'Набережные Челны' },
  { id: 1, displayName: 'Нальчик' },
  { id: 1, displayName: 'Находка' },
  { id: 1, displayName: 'Нефтекамск' },
  { id: 1, displayName: 'Нефтеюганск' },
  { id: 1, displayName: 'Нижневартовск' },
  { id: 1, displayName: 'Нижнекамск' },
  { id: 1, displayName: 'Нижний Новгород' },
  { id: 1, displayName: 'Нижний Тагил' },
  { id: 1, displayName: 'Новокузнецк' },
  { id: 1, displayName: 'Новороссийск' },
  { id: 1, displayName: 'Новосибирск' },
  { id: 1, displayName: 'Норильск' },
  { id: 1, displayName: 'Новый Уренгой' },
  { id: 1, displayName: 'Одинцово' },
  { id: 1, displayName: 'Омск' },
  { id: 1, displayName: 'Орёл' },
  { id: 1, displayName: 'Оренбург' },
  { id: 1, displayName: 'Орехово-Зуево' },
  { id: 1, displayName: 'Орск' },
  { id: 1, displayName: 'Пенза' },
  { id: 1, displayName: 'Пермь' },
  { id: 1, displayName: 'Петрозаводск' },
  { id: 1, displayName: 'Петропавловск-Камчатский' },
  { id: 1, displayName: 'Подольск' },
  { id: 1, displayName: 'Псков' },
  { id: 1, displayName: 'Пушкино' },
  { id: 1, displayName: 'Пятигорск' },
  { id: 1, displayName: 'Раменское' },
  { id: 1, displayName: 'Реутов' },
  { id: 1, displayName: 'Ростов-на-Дону' },
  { id: 1, displayName: 'Рыбинск' },
  { id: 1, displayName: 'Рязань' },
  { id: 1, displayName: 'Самара' },
  { id: 1, displayName: 'Санкт-Петербург' },
  { id: 1, displayName: 'Саранск' },
  { id: 1, displayName: 'Саратов' },
  { id: 1, displayName: 'Северодвинск' },
  { id: 1, displayName: 'Смоленск' },
  { id: 1, displayName: 'Сочи' },
  { id: 1, displayName: 'Ставрополь' },
  { id: 1, displayName: 'Старый Оскол' },
  { id: 1, displayName: 'Стерлитамак' },
  { id: 1, displayName: 'Сургут' },
  { id: 1, displayName: 'Сызрань' },
  { id: 1, displayName: 'Сыктывкар' },
  { id: 1, displayName: 'Таганрог' },
  { id: 1, displayName: 'Тамбов' },
  { id: 1, displayName: 'Тверь' },
  { id: 1, displayName: 'Тольятти' },
  { id: 1, displayName: 'Томск' },
  { id: 1, displayName: 'Тула' },
  { id: 1, displayName: 'Тюмень' },
  { id: 1, displayName: 'Улан-Удэ' },
  { id: 1, displayName: 'Ульяновск' },
  { id: 1, displayName: 'Уссурийск' },
  { id: 1, displayName: 'Уфа' },
  { id: 1, displayName: 'Хабаровск' },
  { id: 1, displayName: 'Химки' },
  { id: 1, displayName: 'Чебоксары' },
  { id: 1, displayName: 'Челябинск' },
  { id: 1, displayName: 'Череповец' },
  { id: 1, displayName: 'Чита' },
  { id: 1, displayName: 'Шахты' },
  { id: 1, displayName: 'Щёлково' },
  { id: 1, displayName: 'Электросталь' },
  { id: 1, displayName: 'Энгельс' },
  { id: 1, displayName: 'Южно-Сахалинск' },
  { id: 1, displayName: 'Якутск' },
  { id: 1, displayName: 'Ярославль' },
];

export const MENU_LIST_LANDING: TMenuItem[] = [
  { id: 1, title: 'Водителям', route: Routes.layoutDrivers },
  { id: 1, title: 'Рекламодателям', route: Routes.layoutAdCompanies },
];

const LandingLayout: FC<IProps> = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  if (width <= 1440) {
    return <LandingMobileLayout>{children}</LandingMobileLayout>;
  }
  return <LandingDesktopLayout>{children}</LandingDesktopLayout>;
};

export default LandingLayout;
