import { Typography } from '@material-ui/core';
import { Instagram } from 'assets/landing';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../../../constants/Routes';
import { maxIPadMiniSize, maxIPadProSize, maxIphoneSize } from '../../index';

const LayoutFooter: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.footerItems}>
        <div className={classes.footerItem}>
          <Typography className={classes.footerHeader}>Контакты</Typography>
          <Typography className={classes.footerDescription}>г. Санкт-Петербург, ул. Боткинская, д.3 к.1</Typography>
          <Typography className={classes.footerDescription}>8(812)4567-221</Typography>
          <Typography className={classes.footerDescription}>
            <a href="mailto:Info@rekcar.com">Info@rekcar.com</a>
          </Typography>
          <Typography className={classes.footerDescription}>
            <Instagram />
          </Typography>
        </div>
        <div className={classes.footerItem}>
          <Typography className={classes.footerHeader}>Реклама</Typography>
          <Typography className={classes.footerDescription} component={NavLink} to={Routes.layoutDrivers}>
            Для водителей
          </Typography>
          <Typography className={classes.footerDescription} component={NavLink} to={Routes.layoutAdCompanies}>
            Для рекламодателей
          </Typography>
        </div>
        <div className={classes.footerItem}>
          <Typography className={classes.footerHeader}>О нас</Typography>
          <Typography
            className={classes.footerDescription}
            component={NavLink}
            to={Routes.privacyPolicy}
            target="_blank"
          >
            Политика конфиденциальности
          </Typography>
          <Typography
            className={classes.footerDescription}
            component={NavLink}
            to={Routes.termsAndConditions}
            target="_blank"
          >
            Пользовательское соглашение
          </Typography>
          <Typography
            className={classes.footerDescription}
            component={NavLink}
            to={Routes.consentToDataProcessing}
            target="_blank"
          >
            Согласие на обработку персональных данных
          </Typography>
          <Typography className={classes.footerDescription}>
            Все права защищены
            <br />© 2021 Rekcar.com
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LayoutFooter;

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      marginTop: 104,
      padding: '0 128px',
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      margin: '64px 0',
      padding: '0 72px',
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      margin: '23px 0',
      padding: '0 16px',
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      margin: '48px 0',
      padding: '0 16px',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  footerItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  footerItem: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      marginBottom: 104,
      width: '33.33%',
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      marginBottom: 64,
      width: '33.33%',
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      marginBottom: 23,
      width: '33.33%',
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      marginBottom: 48,
      width: '50%',
    },
    display: 'flex',
    flexDirection: 'column',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  footerHeader: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      marginBottom: 32,
      fontSize: 22,
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      marginBottom: 16,
      fontSize: 18,
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 600,
  },
  footerDescription: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      marginBottom: 22,
      fontSize: 18,
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      marginBottom: 17,
      fontSize: 14,
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: 'none',
    color: '#000000',
    '& a': {
      textDecoration: 'none',
      color: '#000000',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));
