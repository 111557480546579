const PROTOCOL = process.env.REACT_APP_PROTOCOL || 'https';
const PORT = process.env.REACT_APP_PORT || 443;
const ADMIN_DOMAIN = process.env.ADMIN_DOMAIN || 'admin.rekcar.com';

const ADMIN_HOST = `${PROTOCOL}://${ADMIN_DOMAIN}:${PORT}`;

const env = {
  ADMIN_HOST,
};

export const settings = {
  isProd: process.env.NODE_ENV === 'production',
  baseUrl: process.env.REACT_APP_BASE_URL,
};

export default env;
