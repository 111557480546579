import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  title: {
    margin: 0,
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  subtitle: {
    margin: 0,
    fontWeight: 600,
  },
  main: {
    '& ol': {
      counterReset: 'item',
    },
    '& > ol': {
      padding: 0,
    },
    '& li': {
      display: 'block',
      textAlign: 'justify',
    },
    '& ol > li:before': {
      content: `counters(item, '.') '. '`,
      counterIncrement: 'item',
    },
    '& ul > li:before': {
      content: `'\u2022 '`,
    },
  },
  listHeader: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));

const ConsentToDataProcessing: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <h1 className={classes.title}>
          Согласие пользователя информационного сервиса «Rekcar»
          <br />
          на обработку персональных данных
        </h1>
      </header>
      <main className={classes.main}>
        <ol type="1">
          <li>
            Настоящим я, _____________ (ФИО пользователя) далее – «Субъект Персональных Данных», во исполнение
            требований Федерального закона №152-ФЗ от 27.07.2006 «О персональных данных» свободно, своей волей и в своем
            интересе даю свое согласие, Индивидуальному предпринимателю Осипову Андрею Сергеевичу на обработку своих
            персональных данных, указанных при регистрации путем заполнения веб-формы RekCar.
            <span>
              <br />
            </span>
            RekCar - информационный сервис, сайт (https://Rekcar.com) и мобильное приложение для операционных систем
            Android и iOS по размещению рекламы на автомобилях и оказанию иных услуг, осуществляемых партнёрами.
          </li>
          <li>
            Под персональными данными я понимаю любую информацию, относящуюся ко мне как к Субъекту Персональных Данных,
            в том числе мои фамилию, имя, отчество, дату и место рождения, контактные данные (телефон, факс, электронная
            почта, почтовый адрес), фотографии, и иную информацию, указанную мною при регистрации в приложении Rekcar и
            в процессе его использования.
          </li>
          <li>
            Под обработкой персональных данных я понимаю сбор, систематизацию, накопление, уточнение, обновление,
            изменение, использование, распространение, передачу, обезличивание, блокирование, уничтожение, бессрочное
            хранение и иные действия (операции) с персональными данными.
          </li>
          <li>
            Обработка персональных данных Субъекта Персональных Данных осуществляется исключительно в целях регистрации
            Субъекта Персональных Данных в базе данных информационного сервиса Rekcar с последующим направлением
            Субъекту Персональных Данных почтовых сообщений, смс-уведомлений и push-уведомлений, в том числе рекламного
            содержания, от информационного сервиса Rekcar, его аффилированных лиц и/или субподрядчиков, информационных и
            новостных рассылок и иной информации, а также с целью подтверждения личности Субъекта Персональных Данных
            при использовании информационного сервиса Rekcar.
          </li>
          <li>
            Датой выдачи согласия на обработку персональных данных Субъекта Персональных Данных является дата отправки
            регистрационной формы в информационном сервисе Rekcar.
          </li>
          <li>
            Обработка персональных данных Субъекта Персональных Данных может осуществляться с помощью средств
            автоматизации и/или без использования средств автоматизации в соответствии с действующим законодательством
            РФ и внутренними положениями информационного сервиса Rekcar.
          </li>
          <li>
            Информационный сервис Rekcar принимает необходимые правовые, организационные и технические меры или
            обеспечивает их принятие для защиты персональных данных от неправомерного или случайного доступа к ним,
            уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а
            также от иных неправомерных действий в отношении персональных данных, а также принимает на себя
            обязательство сохранения конфиденциальности персональных данных Субъекта Персональных Данных. Информационный
            сервис Rekcar вправе привлекать для обработки персональных данных Субъекта Персональных Данных
            субподрядчиков, а также вправе передавать персональные данные для обработки своим аффилированным лицам,
            обеспечивая при этом принятие такими субподрядчиками и аффилированными лицами соответствующих обязательств в
            части конфиденциальности персональных данных.
          </li>
          <li>
            Я ознакомлен(а), с тем, что:
            <ul>
              <li>
                настоящее согласие на обработку моих персональных данных, указанных при регистрации в информационном
                сервисе Rekcar, направляемых (заполненных) с использованием информационного сервиса Rekcar, действует в
                течение 20 (двадцати) лет с момента регистрации в информационном сервисе Rekcar;
              </li>
              <li>согласие может быть отозвано мною на основании письменного заявления в произвольной форме;</li>
              <li>
                предоставление персональных данных третьих лиц без их согласия влечет ответственность в соответствии с
                действующим законодательством Российской Федерации.
              </li>
            </ul>
          </li>
        </ol>
      </main>
      <footer>Утверждено и действительно в приложении Rekcar с 20 апреля 2021 года.</footer>
    </div>
  );
};

export default ConsentToDataProcessing;
