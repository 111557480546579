import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  title: {
    margin: 0,
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  subtitle: {
    margin: 0,
    fontWeight: 600,
  },
  main: {
    '& ol': {
      counterReset: 'item',
    },

    '& > ol': {
      padding: 0,
    },

    '& li': {
      display: 'block',
      textAlign: 'justify',
    },

    '& ol > li:before': {
      content: `counters(item, '.') '. '`,
      counterIncrement: 'item',
    },

    '& ul > li:before': {
      content: `'\u2022 '`,
    },

    '& > ol > li:before': {
      fontWeight: 'bold',
    },

    '& > ol > li': {
      marginBottom: theme.spacing(3),
    },
  },
  listHeader: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  footer: {
    paddingLeft: 40,
  },
}));

const TermsAndConditions: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <h1 className={classes.title}>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ и ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>
        <p className={classes.subtitle}>об условиях использования мобильного приложения «Rekcar»</p>
      </header>
      <main className={classes.main}>
        <ol type="1">
          <li>
            <span className={classes.listHeader}>Общие положения</span>
            <ol>
              <li>
                Настоящее Пользовательское соглашение (далее - Соглашение) регламентирует отношения между Индивидуальным
                предпринимателем Осиповым Андреем Сергеевичем, ИНН 165043000573, ОГРНИП 1141215002433, адрес: 195009, г.
                Санкт-Петербург, Боткинская ул., д. 3 корп. 1 (далее – Владелец приложения) и лицом, использующим
                мобильное приложение «Rekcar» (далее - Пользователь и Приложение соответственно), совместно именуемыми -
                Стороны. Условия настоящего Соглашения являются публичной офертой в соответствии со ст. 437 Гражданского
                кодекса Российской Федерации и использование Приложения возможно исключительно на условиях настоящего
                Соглашения.
              </li>
              <li>
                Прохождение процедуры активации Приложения в соответствии с пунктом 2.2 настоящего Соглашения (далее —
                «Регистрация») и дальнейшее использование Приложения является подтверждением согласия со всеми
                условиями, указанными в настоящем Соглашении. Владелец приложения признает Пользователем любое лицо,
                которое прошло процедуру Регистрации в Приложении и использует Приложение в соответствии с его
                функциональным назначением. На таких лиц распространяются положения данного Соглашения в соответствии со
                статьями 437 и 438 Гражданского кодекса Российской Федерации.
              </li>
              <li>
                Принимая условия настоящего Соглашения, Пользователь подтверждает свое согласие на обработку Владельцем
                приложения его персональных данных, предоставленных при регистрации, для формирования и передачи ответа
                Пользователю, а также разрешения возможных претензий. Также Пользователь подтверждает свое согласие с
                передачей указанных выше персональных данных третьим лицам (разработчикам Приложения – в случае
                необходимости корректировать работу Приложения, рекламодателям / рекламопроизводителям, партнерам
                Владельца приложения в целях оказания Пользователю с его согласия иных услуг) и их обработку третьими
                лицами в целях исполнения настоящего Соглашения и реализации функционирования Приложения, а также
                разрешения претензий, связанных с исполнением настоящего Соглашения.
              </li>
              <li>
                Настоящее Соглашение может быть изменено и/или дополнено Владельцем приложения в одностороннем порядке.
                При этом продолжение использования Приложения после внесения изменений и/или дополнений в настоящее
                Соглашение, означает согласие Пользователя с такими изменениями и/или дополнениями. В случае несогласия
                с условиями такого измененного Соглашения, Пользователь обязан отказаться от дальнейшего использования
                Приложения.
              </li>
              <li>
                Обращения, предложения и претензии физических и юридических лиц к Владельцу приложения, связанные с
                содержанием и функционированием Приложения, нарушениями прав и интересов третьих лиц, требований
                законодательства Российской Федерации, а также для запросов уполномоченных законодательством Российской
                Федерации лиц могут быть направлены на адрес электронной почты: info@rekcar.com.
              </li>
              <li>
                Настоящее Соглашение составлено в соответствии с требованиями действующего законодательства Российской
                Федерации. Вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с действующим
                законодательством Российской Федерации.
              </li>
              <li>
                Соглашаясь с условиями настоящего Соглашения, Пользователь подтверждает свои право- и дееспособность,
                подтверждает достоверность введенных им при регистрации данных и принимает на себя всю ответственность
                за их точность, полноту и достоверность.
              </li>
              <li>
                Пользователь принимает на себя все возможные риски, связанные с допущенными им ошибками и неточностями в
                предоставленных данных.
              </li>
            </ol>
          </li>
          <li>
            <span className={classes.listHeader}>Функциональные возможности приложения</span>
            <ol>
              <li>
                Приложение позволяет Пользователям получать денежные средства от рекламодателей / рекламопроизводителей
                за размещение на принадлежащих Пользователям транспортных средствах наружной рекламы и направлять
                фотоотчеты рекламодателям / рекламопроизводителям.
              </li>
              <li>
                До начала использования Приложения Пользователь осуществляет его активацию, путем прохождения процедуры
                Регистрации: своих ФИО, указания в Приложении номера своего телефона, адреса электронной почты,
                налогового статуса (юридическое / физическое лицо), реквизитов банковского счета получателя
                вознаграждения. Активация приложения завершается введением кода из смс-сообщения / push-уведомления на
                указанный Пользователем при регистрации телефонный номер. Введение Пользователем кода из смс-сообщения /
                push-уведомления подтверждает его согласие с условиями настоящего Соглашения.
              </li>
              <li>
                В случае изменения данных, указанных при Регистрации, Пользователь обязуется незамедлительно внести
                изменения в Регистрационные данные через Приложение.
              </li>
              <li>
                Владелец приложения не является субъектом рекламной деятельности (рекламодателем,
                рекламораспространителем, рекламопроизводителем) в соответствии со ст. 3 Федерального закона №38-ФЗ от
                13.03.2006 «О рекламе».
              </li>
              <li>
                Владелец приложения не несет ответственности за рекламу, не соответствующую требованиям действующего
                законодательства Российской Федерации.
              </li>
              <li>
                В целях использования приложения Стороны установили, что рекламой, в соответствии со ст. 3 Федерального
                закона №38-ФЗ от 13.03.2006 «О рекламе» признается информация, распространенная любым способом, в любой
                форме и с использованием любых средств. В рамках использования Приложения средством распространения
                рекламы является принадлежащее Пользователю транспортное средство с размещенной на нем наружной
                рекламой.
              </li>
              <li>
                В целях использования приложения Стороны установили, что под рекламодателем в соответствии со ст. 3
                Федерального закона №38-ФЗ от 13.03.2006 «О рекламе», понимается изготовитель или продавец товара либо
                иное определившее объект рекламирования и (или) содержание рекламы лицо; а под рекламопроизводителем -
                лицо, осуществляющее полностью или частично приведение информации в готовую для распространения в виде
                рекламы форму.
              </li>
              <li>
                Владелец приложения не несет ответственность за правильность и своевременность расчётов между
                Пользователем Приложения и Рекламодателем / Рекламопроизводителем.
              </li>
              <li>Оплата Пользователю осуществляется Рекламодателем / Рекламопроизводителем.</li>
              <li>
                Ни Владелец приложения, ни Пользователь не несут ответственность за объект рекламирования.
                Ответственность за нарушение законодательства Российской Федерации о рекламе несет Рекламодатель /
                Рекламопроизводитель.
              </li>
            </ol>
          </li>
          <li>
            <span className={classes.listHeader}>Права и обязанности пользователя</span>
            <ol>
              <li>Пользователь обязуется надлежащим образом соблюдать условия настоящего Соглашения.</li>
              <li>
                Пользователю предоставляется право использования Приложения исключительно с целью личного
                некоммерческого использования. При этом Пользователь может использовать Приложение исключительно в
                соответствии с настоящим Соглашением.
              </li>
              <li>
                Пользователь обязуется не использовать Приложение в нарушение прав и законных интересов
                правообладателей, третьих лиц, настоящего Соглашения и законодательства Российской Федерации.
              </li>
              <li>
                Пользователю запрещено самостоятельно или с привлечением третьих лиц осуществлять декомпилирование
                Приложения, а также распространять, доводить до всеобщего сведения и предоставлять доступ к Приложению
                иным лицам, осуществлять реверс-инжиниринг Приложения или его отдельных элементов.
              </li>
              <li>
                Пользователю запрещено самостоятельно или с привлечением третьих лиц осуществлять декомпилирование
                Приложения, а также распространять, доводить до всеобщего сведения и предоставлять доступ к Приложению
                иным лицам, осуществлять реверс-инжиниринг Приложения или его отдельных элементов.
              </li>
              <li>
                Любые платежи за услуги связи и мобильного интернета, в том числе оказываемые операторами сотовой связи
                или поставщиками интернет услуг, уплачиваются Пользователем самостоятельно.
              </li>
              <li>
                Пользователь обязан правильно и корректно указывать контактные данные и банковские реквизиты для
                получения вознаграждения. Пользователь несет ответственность за правильность предоставленных реквизитов
                и контактных данных.
              </li>
              <li>
                Пользователь обязан правильно и корректно указывать информацию о добавленном транспортном средстве
                (город использования, марку и модель, класс, год выпуска, тип кузова, цвет, VIN номер, гос.рег.знак,
                сведения об участии в дорожно-транспортных происшествий).
              </li>
              <li>
                Пользователь обязан размещать в приложении фотоотчет в указанный рекламодателем / рекламопроизводителем
                срок и за обозначенный им отчетный период.
              </li>
              <li>
                Предоставляемые Пользователем в Приложении фотографии должны быть надлежащего качества, позволяющего
                установить целостность оклеивающих элементов и подтвердить данные одометра. Пользователь несет
                ответственность за качество предоставляемых в Приложение фотографий.
              </li>
              <li>
                Пользователь не в праве осуществлять подлог фотографий, фотографировать другие транспортные средства
                вместо указанного в Приложении.
              </li>
              <li>
                Пользователь вправе при необходимости обращаться в контактный центр Владельца приложения по телефону:
                8-800-101-59-96, или на адрес электронной почты: info@rekcar.com.
              </li>
              <li>
                Пользователь в праве отключать функцию сбора геоданных в Приложении и отключать получение уведомлений
                Приложения.
              </li>
            </ol>
          </li>
          <li>
            <span className={classes.listHeader}>Права и обязанности владельца приложения</span>
            <ol>
              <li>
                Владелец приложения не вправе передавать права и обязанности по настоящему Соглашению третьим лицам в
                целях исполнения настоящего Соглашения, без дополнительного согласия Пользователя.
              </li>
              <li>
                Пользователь, соглашаясь с данной офертой, дает свое информированное и добровольное согласие на участие
                в стимулирующих, рекламных, маркетинговых и иных мероприятиях, направленных на продвижение услуг
                Владельца приложения, партнеров Владельца приложения и иных третьих лиц. Владелец приложения вправе
                направлять Пользователю любым способом информацию о функционировании Приложения, в том числе на адрес
                электронной почты или номер телефона, указанный Пользователем, а также направлять собственные или любых
                третьих лиц информационные, рекламные или иные сообщения, или размещать соответствующую информацию в
                самом Приложении.
              </li>
              <li>
                Владелец приложения вправе заблокировать доступ Пользователя к Приложению в случае обнаружения нарушений
                Пользователем обязанностей, указанных в разделе 3 настоящего Соглашения.
              </li>
              <li>
                Владелец приложения оставляет за собой право в любой момент расторгнуть настоящее Соглашение по
                организационным или техническим причинам в одностороннем порядке, удалив Приложение с мобильного
                устройства Пользователя или заблокировав возможность его использования.
              </li>
              <li>
                В целях улучшения и повышения стабильности работы Приложения Владелец приложения вправе собирать,
                хранить и обрабатывать статистическую информацию об использовании Пользователем Приложения.
              </li>
            </ol>
          </li>
          <li>
            <span className={classes.listHeader} id="privacy-policy">
              Политика конфиденциальности
            </span>
            <ol>
              <li>
                Настоящим положением устанавливается политика конфиденциальности Приложения, которая регулирует
                использование вами программного приложения Rekcar (“Приложение”) для мобильных устройств.
              </li>
              <li>
                Приложение получает информацию, которую Пользователь предоставляет, когда загружает и регистрируется в
                приложении. Предоставляется следующая информация: (а) ФИО, адрес электронной почты, номер телефона, и
                другую регистрационную информацию; (б) информацию, которую вы предоставляете нам, когда обращаетесь к
                нам за помощью; (в) информация, которую вы вводите в нашу систему при использовании Приложения, например
                контактная информация и информация о своих реквизитах. Администратор также может использовать
                предоставленную Пользователем информацию для того, чтобы время от времени связываться с вами и
                предоставлять вам важную информацию.
              </li>
              <li>
                Приложение может автоматически собирать определенную информацию, включая, но не ограничиваясь этим, тип
                используемого вами мобильного устройства, уникальный идентификатор вашего мобильного устройства,
                IP-адрес вашего мобильного устройства, Вашу мобильную операционную систему, тип используемых вами
                мобильных интернет-браузеров и информацию о том, как Пользователь использует Приложение.
              </li>
              <li>
                Во время использования Приложения, Пользователь, может включить технологию GPS (или другую подобную
                технологию) для определения текущего местоположения. Это необходимо для того, чтобы построить пройденный
                маршрут и отобразить пройденный маршрут на карте в административной панели. Если Пользователь не желает,
                чтобы Администратор использовал его местоположение для целей, указанных выше, Пользователь должен
                отключить службы определения местоположения для Приложения, расположенного в настройках его учетной
                записи или в настройках мобильного телефона и/или в мобильном приложении.
              </li>
              <li>
                Администратор будет передавать персональную информацию третьим лицам только теми способами, которые
                описаны в настоящем положении о политике конфиденциальности. Администратор может раскрывать
                предоставленную Пользователем и автоматически собранную информацию:
                <ul>
                  <li>
                    в соответствии с требованиями закона, например, для исполнения повестки в суд или аналогичного
                    юридического процесса;
                  </li>
                  <li>
                    когда мы добросовестно считаем, что раскрытие информации необходимо для защиты наших прав, защиты
                    вашей безопасности или безопасности других лиц, расследования мошенничества или ответа на запрос
                    правительства;
                  </li>
                  <li>
                    с нашими доверенными поставщиками услуг, которые работают от нашего имени, не имеют независимого
                    использования информации, которую мы им раскрываем, и согласились придерживаться правил, изложенных
                    в настоящем положении о политике конфиденциальности;
                  </li>
                  <li>
                    если приложение участвует в слиянии, приобретении или продаже всех или части своих активов, вы
                    будете уведомлены по электронной почте и/или заметным уведомлением на нашем веб-сайте о любом
                    изменении права собственности или использования этой информации, а также о любом выборе, который вы
                    можете иметь в отношении этой информации.
                  </li>
                </ul>
              </li>
              <li>
                В то время как Приложение Rekcar не устанавливает cookies самостоятельно, некоторые из наших партнеров
                могут установить cookies самостоятельно. Файл cookie может быть установлен при использовании стороннего
                сервиса совместно с Rekcar.
              </li>
              <li>
                Приложение будет хранить предоставленные Пользователем данные до тех пор, пока используется Приложение,
                и в течение разумного времени после этого. Если Пользователь против хранения, предоставленных им данных,
                то необходимо связаться с Администратором по адресу info@rekcar.com. Обратите внимание, что для
                правильной работы приложения могут потребоваться некоторые или все предоставленные Пользователем данные.
              </li>
              <li>
                Администратор не использует Приложение для сознательного запроса данных у детей в возрасте до 13 лет.
                Если родителю или опекуну становится известно, что его ребенок предоставил в Приложении информацию без
                их согласия, он должен связаться с Администратором по адресу: avocadonessapp@gmail.com. Информация будет
                удалена в разумные сроки.
              </li>
              <li>
                Администратор предоставляет физические, электронные и процедурные гарантии для защиты информации,
                которая предоставляется Пользователем.
              </li>
              <li>
                Используя приложение, Пользователь соглашается с тем, что Администратор обрабатывает вашу информацию в
                соответствии с настоящей Политикой конфиденциальности в настоящее время и с внесенными нами поправками.
                &quot;Обработка&quot; означает использование файлов cookie на компьютере/мобильном устройстве или
                прикосновение к информации любым способом, включая, но не ограничиваясь этим, сбор, хранение, удаление,
                использование, объединение и раскрытие информации, все эти действия будут осуществляться в Российской
                Федерации.
              </li>
            </ol>
          </li>
          <li>
            <span className={classes.listHeader}>Гарантии и ответственность сторон</span>
            <ol>
              <li>
                Для исполнения настоящего Соглашения Владелец приложения может привлекать третьих лиц. Указанным третьим
                лицам предоставляются такие же права, как и Владельцу приложения, в том числе в отношении персональных
                данных Пользователя. Владелец приложения гарантирует нераспространение персональных данных Пользователя
                такими третьими лицами.
              </li>
              <li>
                Пользователь гарантирует, что не будет предпринимать каких-либо действий, направленных исключительно на
                причинение ущерба Владельцу приложения, рекламодателям / рекламопроизводителям, правообладателям или
                иным лицам.
              </li>
              <li>
                В случае нарушения правил использования Приложения, указанных в разделе 3 настоящего Соглашения, а также
                в случае нарушения пункта 5.2 настоящего Соглашения, Пользователь обязуется возместить Владельцу
                приложения и рекламодателю / рекламопроизводителю вред, причиненный такими действиями.
              </li>
              <li>
                Если Пользователем не доказано обратное, любые действия, совершенные с использованием его мобильного
                устройства, считаются совершенными соответствующим Пользователем. В случае несанкционированного доступа
                к его мобильному устройству Пользователь обязан незамедлительно сообщить об этом Владельцу приложения.
              </li>
              <li>
                Владелец приложения не гарантирует, что Приложение и его отдельные элементы не содержат ошибок и будут
                функционировать в соответствии с ожиданиями Пользователя.
              </li>
            </ol>
          </li>
          <li>
            <span className={classes.listHeader}>Заключительные положения</span>
            <ol>
              <li>
                В случае возникновения любых споров или разногласий, связанных с исполнением настоящего Соглашения,
                Стороны обязуются соблюдать претензионный порядок разрешения споров. Срок ответа на претензию – 30
                (тридцать) календарных дней. В случае, если спор не урегулирован в претензионном порядке, он подлежит
                передаче на рассмотрение соответствующего компетентного суда по месту нахождения Владельца приложения в
                соответствии с требованиями гражданского процессуального кодекса Российской Федерации и Арбитражного
                процессуального кодекса Российской Федерации.
              </li>
              <li>
                Настоящее Соглашение вступает в силу для Пользователя с момента Регистрации в соответствии с пунктом 2.2
                Соглашения и действует до тех пор, пока не будет изменено или расторгнуто по инициативе Владельца
                приложения.
              </li>
              <li>
                Если какое-либо из положений настоящего Соглашения будет признано недействительным в порядке,
                предусмотренном действующим законодательством Российской Федерации, это не оказывает влияния на
                действительность или применимость остальных положений настоящего Соглашения.
              </li>
            </ol>
          </li>
        </ol>
      </main>
      <footer className={classes.footer}>Утверждено и действительно в приложении Rekcar с 20 апреля 2021 года.</footer>
    </div>
  );
};

export default TermsAndConditions;
