import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import { FieldErrors } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { maxIphoneSize } from '../../index';

interface IHelper {
  id: number;
  displayName: string;
}

type TOptionTwo = {
  value: number;
};

type TCustomSelectProps = {
  options: IHelper[] | TOptionTwo[] | any;
  errors?: FieldErrors;
  name: string;
  value: string | number | IHelper | TOptionTwo | undefined | any;
};

const CitySelectLanding: FC<TCustomSelectProps> = ({ options, value, errors, name }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <Autocomplete
        value={value as any}
        options={options as any}
        classes={{ inputRoot: classes.selectInput }}
        onChange={() => {}}
        getOptionLabel={(option) => option.displayName || option.title || option.name || ''}
        renderInput={(params) => (
          <TextField {...params} className={classes.input} placeholder="Список городов" variant="outlined" />
        )}
      />
      {errors && <ErrorMessage as="span" className={classes.error} errors={errors} name={name} />}
    </FormControl>
  );
};

export default React.memo(CitySelectLanding);

const useStyles = makeStyles((theme) => ({
  formControl: {
    [theme.breakpoints.up(maxIphoneSize)]: {
      width: 450,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      width: 324,
    },
    width: 450,
    display: 'flex',
  },
  label: {
    fontWeight: 300,
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '19px',
    paddingBottom: 4,
  },
  selectInput: {
    height: 'auto',
    padding: '0px 40px 0px 0px !important',
  },
  input: {
    '& input': {
      padding: '7px 10px !important',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 24,
    },
  },
  error: {
    color: theme.palette.secondary.light,
  },
}));
