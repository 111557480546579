import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack';
import React from 'react';

let snackbarRef: WithSnackbarProps;

export const SnackbarUtilsConfigurator: React.FC = () => {
  snackbarRef = useSnackbar();
  return null;
};

export default {
  show(msg: string, variant: VariantType = 'default'): void {
    snackbarRef.enqueueSnackbar(msg, { variant });
  },
};
