import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LandingLayout, { maxIPadMiniSize, maxIPadProSize, maxIphoneSize, CONST_LIST_CITY } from '../index';
import {
  AllTimeSupport,
  Computers,
  Formalization,
  HighCoverage,
  HighQualityCA,
  LowCast,
  SafePasting,
} from 'assets/landing';
import LayoutHeader from '../elements/header';
import LayoutFooter from '../elements/footer';
import { Controller, useForm } from 'react-hook-form';
import { IPromotionsFilters } from '../../../interfaces/Promotions';
import CitySelectLanding from '../elements/select/CitySelectLanding';

const LayoutAdCompanies: React.FC = () => {
  const classes = useStyles();
  const { control, errors } = useForm<IPromotionsFilters>({});

  return (
    <LandingLayout>
      <Grid className={classes.root}>
        <LayoutHeader />
        <Grid className={classes.howItWork}>Как это работает</Grid>
        <Grid>
          <Typography className={classes.descriptionHowItWork}>
            1. Заключаем договор. Согласуем с вами класс машин, на которых будет размещена реклама, цены, эскизы
            наклеек.
            <br />
            2. Подбираем нужные авто. Отсеиваем старые и дефектные.
            <br />
            3. Оклеиваем машины в партнерском центре.
            <br />
            4. Контролируем рекламу. Водители должны проехать не меньше 200 км в месяц и каждые две недели присылать
            свежие фото машины — показать целостность наклеек.
            <br />
            5. Вы видите все отчеты и статистику в рекламном кабинете.
          </Typography>
        </Grid>
        <Grid className={classes.howItWork}>Где это работает</Grid>
        <Grid className={classes.descriptionWhereItWork}>
          Работает в любом населенном пункте РФ с населением от 100 тыс. чел.
        </Grid>
        <Grid>
          <form className={classes.formControl}>
            <Controller
              name="city"
              control={control}
              render={(props) => (
                <CitySelectLanding name="city" value={props.value} options={CONST_LIST_CITY} errors={errors} />
              )}
            />
          </form>
        </Grid>
        <div className={classes.plus}>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <HighCoverage />
            </div>
            <div className={classes.plusHeader}>Высокий охват</div>
            <div className={classes.plusDescription}>
              Ваша реклама найдет аудиторию везде: во дворе дома, у любимого магазина, на парковке ТЦ.
            </div>
          </div>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <SafePasting />
            </div>
            <div className={classes.plusHeader}>Подбор авто</div>
            <div className={classes.plusDescription}>
              Подберем автомобили по таким параметрам, как: город, марка, модель, год выпуска, тип кузова, цвет.
            </div>
          </div>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <AllTimeSupport />
            </div>
            <div className={classes.plusHeader}>Реклама 24/7</div>
            <div className={classes.plusDescription}>
              Ваша реклама работает круглосуточно в отличие от общественного транспорта.
            </div>
          </div>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <Formalization />
            </div>
            <div className={classes.plusHeader}>Креативный способ продвижения</div>
            <div className={classes.plusDescription}>
              Ваша реклама всегда заметна — яркое движущееся авто привлекает больше внимания, чем статичный баннер, и
              выгодно выделяется от монотонного общественного транспорта.
            </div>
          </div>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <HighQualityCA />
            </div>
            <div className={classes.plusHeader}>Качественная ЦА</div>
            <div className={classes.plusDescription}>
              Активные, платежеспособные группы людей, лояльные к вашему бренду.
              <br />
              1. Водители такси и их пассажиры, средний возраст – 25-45 лет.
              <br />
              2. Частные водители и их ближайшее окружение (родственники, друзья, коллеги, знакомые). Возрастной
              диапазон – 0+.
            </div>
          </div>
          <div className={classes.plusItem}>
            <div className={classes.plusLogo}>
              <LowCast />
            </div>
            <div className={classes.plusHeader}>Низкая стоимость</div>
            <div className={classes.plusDescription}>
              Реклама на авто выгоднее, чем на билбордах или общественном транспорте. Не требует согласования с органами
              власти. Доступно большое количество автомобилей для рекламы.
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={classes.lowerBanner} container direction="row">
        <Grid xs justify="center" className={classes.lowerBannerItem} item container direction="column" wrap="nowrap">
          <Grid item className={classes.lowerBannerText}>
            Онлайн-мониторинг и медиааналитика
          </Grid>
          <Grid className={classes.lowerBannerDescription} item container>
            В рекламном кабинете вы можете отслеживать маршруты рекламной кампании по GPS-меткам, просматривать
            фотоотчеты водителей о качестве и целостности наклеек на авто в режиме реального времени. По итогам
            рекламной кампании вам будет предоставлена вся необходимая аналитика
          </Grid>
        </Grid>
        <Grid xs item container justify="flex-end" alignItems="flex-end">
          <img src={Computers} className={classes.smartPhoto} alt="layoutPhoto" />
        </Grid>
      </Grid>
      <LayoutFooter />
    </LandingLayout>
  );
};

export default LayoutAdCompanies;

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  formControl: {
    marginLeft: 128,
    marginBottom: 104,
    [theme.breakpoints.up(maxIPadProSize)]: {
      margin: '0 128px',
      marginBottom: 104,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      margin: '0 72px',
      marginBottom: 64,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      margin: '0 16px',
      marginBottom: 48,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      margin: '0 16px',
      marginBottom: 48,
    },
  },
  howItWork: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      margin: '0 128px',
      fontSize: 64,
      marginBottom: 48,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      margin: '0 72px',
      fontSize: 40,
      marginBottom: 16,
    },
    [theme.breakpoints.down(maxIPadMiniSize)]: {
      margin: '0 16px',
      fontSize: 32,
      marginBottom: 16,
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  descriptionHowItWork: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      margin: '0 128px',
      fontSize: 24,
      lineHeight: '29px',
      marginBottom: 104,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      margin: '0 72px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 64,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      margin: '0 16px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 48,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      margin: '0 16px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 48,
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  descriptionWhereItWork: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      margin: '0 128px',
      fontSize: 24,
      lineHeight: '29px',
      marginBottom: 32,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      margin: '0 72px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 16,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      margin: '0 16px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 16,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      margin: '0 16px',
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 16,
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  lowerBanner: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      paddingLeft: 128,
      minHeight: 549,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      paddingLeft: 72,
      minHeight: 436,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      paddingLeft: 16,
      minHeight: 436,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      paddingLeft: 16,
      minHeight: 549,
    },
    background: '#CC2631',
  },
  lowerBannerItem: {
    marginRight: 16,
    marginBottom: 16,
  },
  lowerBannerText: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      fontSize: 64,
      lineHeight: '77px',
      paddingTop: 52,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      fontSize: 40,
      lineHeight: '48px',
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      fontSize: 32,
      lineHeight: '38px',
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      fontSize: 24,
      lineHeight: '24px',
      paddingTop: 32,
    },
    fontFamily: 'Golos',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  smartPhoto: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      width: 679,
      height: 536,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      width: 378,
      height: 290,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      width: 365,
      height: 290,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      width: 359,
      height: 284,
    },
  },
  plus: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      margin: '0 128px',
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      margin: '0 72px',
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      margin: '0 16px',
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      margin: '0 16px',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  plusItem: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      width: 450,
      marginBottom: 64,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      width: 267,
      marginBottom: 40,
    },
    [theme.breakpoints.between(maxIphoneSize, maxIPadMiniSize)]: {
      width: 267,
      marginBottom: 64,
    },
    [theme.breakpoints.down(maxIphoneSize)]: {
      width: 343,
      marginBottom: 48,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  plusLogo: {
    display: 'flex',
    justifyContent: 'center',
  },
  plusHeader: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      fontSize: 32,
      lineHeight: '38px',
      marginTop: 23.48,
      marginBottom: 32,
    },
    [theme.breakpoints.between(maxIPadMiniSize, maxIPadProSize)]: {
      fontSize: 20,
      lineHeight: '24px',
      margin: '16px 0',
    },
    [theme.breakpoints.down(maxIPadMiniSize)]: {
      fontSize: 24,
      lineHeight: '29px',
      margin: '16px 0',
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  plusDescription: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      fontSize: 24,
      lineHeight: '29px',
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      fontSize: 16,
      lineHeight: '19px',
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  mobile: {
    marginTop: 88,
  },
  lowerBannerDescription: {
    [theme.breakpoints.up(maxIPadProSize)]: {
      fontSize: 24,
      lineHeight: '29px',
      margin: '48px 0',
    },
    [theme.breakpoints.down(maxIPadProSize)]: {
      fontSize: 16,
      lineHeight: '19px',
      marginTop: 32,
    },
    fontFamily: ['Golos'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#FFFFFF',
  },
}));
