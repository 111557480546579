import React, { FC } from 'react';
import { MenuButton } from 'assets/landing';
import { makeStyles } from '@material-ui/core/styles';
import { Logo, OrgName } from '../../../../../assets/images';
import { SwipeableDrawer } from '@material-ui/core';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { maxIPadMiniSize } from '../../../index';
import MenuList from '../../menuList';

type IProps = {};

const LandingMobileLayout: FC<IProps> = ({ children }) => {
  const classes = useStyles();

  const [isOpen, setOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const list = () => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <MenuList />
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.shiftMenu}>
        <div className={classes.header}>
          <Button onClick={toggleDrawer(true)} className={classes.menuButton}>
            <MenuButton className={classes.menuButtonIcon} />
          </Button>
          <div className={classnames(classes.logoNName, classes.menuButtonIcon)}>
            <Logo className={classes.logoSVG} />
            <OrgName className={classes.orgName} />
          </div>
          <div className={classes.menuButtonIcon}> </div>
        </div>
      </div>
      <main className={classnames(classes.content, classes.contentShift)}>{children}</main>
      <SwipeableDrawer
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableBackdropTransition
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
};

export default LandingMobileLayout;

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: 232,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  shiftMenu: {
    position: 'fixed',
    width: '100%',
    height: 72,
    zIndex: 1,
    background: '#F2F2F2',
    boxShadow: '4px 0px 49px 8px rgba(0, 0, 0, 0.10)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  menuButton: {
    padding: 0,
    [theme.breakpoints.up(maxIPadMiniSize)]: {
      height: 72,
      width: 72,
    },
    [theme.breakpoints.down(maxIPadMiniSize)]: {
      height: 56,
      width: 56,
    },
  },
  menuButtonIcon: {
    [theme.breakpoints.up(maxIPadMiniSize)]: {
      height: 72,
      width: 72,
    },
    [theme.breakpoints.down(maxIPadMiniSize)]: {
      height: 56,
      width: 56,
    },
  },
  logoNName: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 49,
  },
  logoSVG: {
    [theme.breakpoints.up(maxIPadMiniSize)]: {
      height: 28.92,
      width: 23.51,
    },
    [theme.breakpoints.down(maxIPadMiniSize)]: {
      height: 21.69,
      width: 17.63,
    },
    marginBottom: 7.66,
  },
  orgName: {
    [theme.breakpoints.up(maxIPadMiniSize)]: {
      height: 11.41,
      width: 48.64,
    },
    [theme.breakpoints.down(maxIPadMiniSize)]: {
      height: 8.56,
      width: 36.48,
    },
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    position: 'relative',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#FFFFFF',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
