import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ palette }: Theme) => ({
  container: {
    margin: '0 auto',
    maxWidth: '602px',
    textAlign: 'center',
    padding: '24px 0',
    marginTop: 'calc((100vh - 718px) / 2)',
  },
  picture: {
    marginBottom: '64px',
  },
  text: {
    fontWeight: 500,
    fontSize: '18px',
    margin: '0 0 12px 0',
  },
  link: {
    color: palette.primary.main,
    textDecoration: 'underline',
    fontSize: '24px',
  },
}));

const NoMatchContainer: React.FC = () => {
  const classes = useStyles();

  const link = '/';

  return (
    <div className={classes.container}>
      <p className={classes.text}>К сожалению этот раздел сейчас не доступен</p>
      <Link to={link} className={classes.link}>
        НАЧАЛЬНАЯ СТРАНИЦА
      </Link>
    </div>
  );
};

export default NoMatchContainer;
