import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Routes } from 'constants/Routes';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from 'services/Snackbar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import theme from 'services/theme';
import NoMatchContainer from 'components/containers/no-match';
import LayoutAdCompanies from './components/landing/ads';
import LandingDrivers from './components/landing/drivers';
import TermsAndConditions from 'components/containers/termsAndConditions';
import ConsentToDataProcessing from 'components/containers/consentToDataProcessing';

const App = () => (
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
      <SnackbarProvider
        preventDuplicate
        maxSnack={3}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <>
          <SnackbarUtilsConfigurator />
          <CssBaseline />
          <BrowserRouter>
            <Switch>
              <Route path={Routes.layoutDrivers} exact>
                <LandingDrivers />
              </Route>

              <Route path={Routes.layoutAdCompanies} exact>
                <LayoutAdCompanies />
              </Route>

              <Route path={Routes.termsAndConditions} exact>
                <TermsAndConditions />
              </Route>

              <Route path={Routes.consentToDataProcessing} exact>
                <ConsentToDataProcessing />
              </Route>

              <Redirect from="/" to={Routes.layoutDrivers} exact />

              <Route path="*" exact>
                <NoMatchContainer />
              </Route>
            </Switch>
          </BrowserRouter>
        </>
      </SnackbarProvider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>
);

export default App;
