import { createMuiTheme, Theme } from '@material-ui/core/styles';

const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7D7D7D',
      light: '#f8fbfc',
      dark: '#CACACA',
    },
    secondary: {
      main: '#2D9CDB',
      light: '#CC2631',
      dark: '#EB5757',
    },
    text: {
      primary: '#000000',
      secondary: '#7D7D7D',
      disabled: '#e0e0e0',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'Roboto',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Golos',
    ].join(','),
    h1: {
      fontWeight: 300,
      fontStyle: 'normal',
      fontSize: 24,
      lineHeight: '28px',
      color: '#7D7D7D',
    },
    h2: {
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 18,
      lineHeight: '21px',
      color: '#000000',
    },
    h3: {
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: 15,
      lineHeight: '18px',
      color: '#000000',
    },
  },
});

export default theme;
