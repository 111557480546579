import React, { FC } from 'react';
import classnames from 'classnames';
import { Logo, OrgName } from 'assets/images';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Typography } from '@material-ui/core';
import MenuList from '../../menuList';

type IProps = {};

const LandingDesktopLayout: FC<IProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        anchor="left"
        className={classnames(classes.drawer, classes.drawerOpen)}
        classes={{
          paper: classnames(classes.drawerOpen),
        }}
      >
        <div className={classes.listMenu}>
          <div>
            <div className={classes.paper}>
              <div className={classes.sideBar}>
                <div>
                  <Logo className={classes.logoSVG} />
                </div>
                <Typography component="h1" variant="h5">
                  <OrgName className={classes.orgNameOpen} />
                </Typography>
              </div>
            </div>
            <MenuList />
          </div>
        </div>
      </Drawer>
      <main className={classnames(classes.content, classes.contentShift)}>{children}</main>
    </div>
  );
};

export default LandingDesktopLayout;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 200,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sideBar: {
    marginTop: 63,
    height: 168,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  logoSVG: {
    height: 80.6,
    width: 64.49,
    margin: '10px',
  },
  listMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  orgNameOpen: {
    height: '32px',
    width: '136.37px',
    margin: '10px',
  },
  root: {
    display: 'flex',
    height: '100vh',
    margin: 0,
    '& div div': {
      borderRight: 0,
    },
  },
  drawer: {
    width: 235,
    flexShrink: 0,
    background: '#F2F2F2',
    boxShadow: '4px 0px 49px 8px rgba(0, 0, 0, 0.15)',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    position: 'relative',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#FFFFFF',
    boxShadow: 'inset 4px 0px 49px 8px rgba(0, 0, 0, 0.1)',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerOpen: {
    width: 235,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    position: 'inherit',
  },
}));
